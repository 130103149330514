@import url(https://fonts.googleapis.com/css?family=Ubuntu&display=swap);
body{background-image:url(https://snakelings.s3.us-east-2.amazonaws.com/snakeDataImages/backgroundInverted.jpg);background-size:cover;background-repeat:no-repeat;background-attachment:fixed;color:#616c72 !important;font-family:'Ubuntu', sans-serif !important;padding-top:150px;margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Osxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#a9a9a9 !important}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

.App{text-align:center}.App-logo{height:40vmin;pointer-events:none}@media (prefers-reduced-motion: no-preference){.App-logo{-webkit-animation:App-logo-spin infinite 20s linear;animation:App-logo-spin infinite 20s linear}}.App-header{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:white}.App-link{color:#61dafb}@-webkit-keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}

.NavBar{display:block}.NavBar #snakeLogo{height:100px}.NavBar #googleLogo{height:20px}.NavBar .logInButton{padding:10px}.NavBar .logOutButton{margin-left:8px}.NavBar .fixed-top{box-shadow:0 10px 10px #4d4d4d;background-image:-webkit-gradient(linear, left top, left bottom, from(lightgray), to(#333));background-image:linear-gradient(lightgray, #333)}.NavBar .userProfileIcon{font-size:25px}

.ProfileForm{display:block}.ProfileForm .formModal{background-color:lightgray}.existingName{color:red}

.Home{display:block}.Home #homePic{border-radius:50%;border:5px solid #616c72}

.SightingForm{display:inline-block}

.Sighting{display:block}.Sighting .card-horizontal{display:flex;flex:1 1 auto;background-color:lightgray}.Sighting .reportedSnakeCard{height:100%;width:100%}.Sighting .reportedImage{height:200px;width:250px;object-fit:cover}.Sighting .reportCard{width:400px;background-color:lightgray}

.SingleSnake{display:block}.SingleSnake .singleSnakeImage{width:100%;border-radius:3em;border:4px solid #616c72;margin:5px}.SingleSnake p{font-size:large}.SingleSnake .venom{color:red;font-size:larger}.SingleSnake .non-venom{color:green;font-size:larger}.SingleSnake .snakeInfo,.SingleSnake .additionalInfo{background-color:lightgray;border-radius:10px;border:3px,solid,#616c72;padding-top:1em;padding-bottom:1em}.SingleSnake a:link,.SingleSnake a:visited,.SingleSnake a:hover,.SingleSnake a:active{color:#616c72}

.UserSighitngs{display:block}

.Sightings{display:block}

.Snake{display:block}.Snake .snakeImage{border-radius:2em;border:2px solid #616c72;margin-bottom:5px;box-shadow:5px 5px 10px #a9a9a9}

.StateMap{display:block}path{pointer-events:all}path:hover{fill:#ff4800;cursor:pointer}.WI,.IL,.KY,.TN,.AL{fill:lightgreen}

.SnakeForm{display:block}.formClose{color:red}.bodyOptionImg,.tailOptionImg{width:200px}

.Snakes{display:block}.Snakes #search-bar{border-radius:1em}.Snakes .searchButton{border-radius:1em}

.UserProfile{display:block}.UserProfile #profileContainer{display:flex;justify-content:center}.UserProfile #profileContainer .userProfileCard{margin:2em;text-align:left;background-color:lightgray;max-width:75%}.UserProfile #profileContainer .userProfileCard .card-body{margin-top:25%}.UserProfile #profileContainer #profileData{margin:20px}.UserProfile #profileContainer img{border-radius:15em;border:2px solid black}.UserProfile .profileEdit{text-align:center}.UserProfile .sightingButtons{margin-top:50px}


@import './variables';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css?family=Ubuntu&display=swap');

body {
  background-image: url(https://snakelings.s3.us-east-2.amazonaws.com/snakeDataImages/backgroundInverted.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: $slate !important;
  font-family: 'Ubuntu', sans-serif !important;
  padding-top: 150px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Osxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $darkGray !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
